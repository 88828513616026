import SearchDealsGrid from './grid/grid';
import type { RowType } from '@odo/components/grid/types';
import type { Priority, GridProduct } from './types';
import { SORT_DIR } from '@odo/components/grid/types';
import { prepProductRow } from './utils';
import { dateObjectToIso } from '@odo/utils/date';
import { useCallback, useEffect, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { gql } from '@apollo/client';
import { range } from '@odo/utils/array';
import { createClient } from '@odo/services/urql';
import {
  AttributeCode,
  type ApiProduct,
  type InputProductFilter,
  type InputProductSelect,
  type QueryProductsArgs,
  type QueryProductsOutput,
} from '@odo/types/api';
import { FilterType } from './filters';
import {
  useActiveFilters,
  useActiveFiltersLabel,
  useSearchFiltersContext,
  useSearchEditorContext,
} from '@odo/contexts/search';
import { useAttributeOptions } from '@odo/hooks/attributes';

const MAX_PAGE_SIZE = 100;

const pageSizeOptions = [
  ...range(10, MAX_PAGE_SIZE, 10),
  ...range(MAX_PAGE_SIZE * 2, MAX_PAGE_SIZE * 5, MAX_PAGE_SIZE),
];

const GET_DEALS = gql`
  query getProducts($filter: InputProductFilter) {
    getProducts(filter: $filter) {
      id
      buyer
      salesAssistant
      brand
      sku
      preview
      name
      price
      cost
      retail
      activeFromDate
      activeToDate
      status
      priority
      thumbnail
      dealType
      campaign
      categories {
        categoryName
        categoryId
      }
      inventory {
        qty
      }
      isSampleReceived
      isPhotographedByStudio
      xtdDaysConfirmed
    }
  }
`;

const filterFieldMap = {
  id: 'ID',
  name: 'NAME',
  brand: 'BRAND',
  sku: 'SKU',
  buyer: 'BUYER',
  salesAssistant: 'SALES_ASSISTANT',
  activeFromDate: 'ACTIVE_FROM_DATE',
  activeToDate: 'ACTIVE_TO_DATE',
  price: 'PRICE',
  cost: 'COST',
  status: 'STATUS',
  categories: 'SHOP',
  dealType: 'DEAL_TYPE',
  campaign: 'CAMPAIGN',

  // url: 'URL_KEY', // TODO: add this filter input
  // quantity: 'QUANTITY', // disabled because the API doesn't support this option.
};

const filterConditionMap = {
  greaterThan: 'GREATER_THAN',
  greaterThanOrEqualsTo: 'GREATER_THAN_OR_EQUALS_TO',
  lessThan: 'LESS_THAN',
  lessThanOrEqualsTo: 'LESS_THAN_OR_EQUALS_TO',
  notAny: 'NOT_EQUALS_ANY_OF',
  not: 'NOT_EQUAL',
  like: 'LIKE',
  // NOTE: don't ask me why this means exact to the API
  exact: 'IN',
};

type SelectQuery = InputProductSelect;
type QueryParams = InputProductFilter & { signal?: AbortSignal };

/**
 * Deal query.
 */
const queryDeals = async ({
  select,
  limit,
  page = 1,
  orderBy = { field: 'activeFromDate', direction: SORT_DIR.desc },
  signal,
}: QueryParams = {}) => {
  const { data, error } = await createClient({ signal })
    .query<QueryProductsOutput, QueryProductsArgs>(
      GET_DEALS,
      {
        filter: {
          limit,
          page,
          orderBy,
          ...(select && select.length > 0 ? { select } : {}),
        },
      },
      { requestPolicy: 'network-only' }
    )
    .toPromise();

  // only throw errors if we don't get any data back
  if (!(data && data.getProducts) && error) {
    if (error.networkError) {
      throw new Error('Network error');
    }

    const [firstGraphQLError] = error.graphQLErrors;
    if (firstGraphQLError) {
      throw new Error(firstGraphQLError.message);
    }
  }

  return data && data.getProducts ? data.getProducts : [];
};

/**
 * Deal loading function.
 */
const loadDeals = async ({
  select,
  limit,
  page = 1,
  orderBy,
  setIsLoading,
  setRows,
  signal,
}: QueryParams & {
  setIsLoading: (isLoading: boolean) => void;
  setRows: (rows: RowType<GridProduct>[]) => void;
}) => {
  // track whether this function has been aborted.
  let isActive = true;

  setIsLoading(true);

  const loadingToastId = toast.loading('Loading deals', {
    id: 'searching-deals',
    style: { background: '#0093D0', color: '#f2f2f2' },
    iconTheme: { primary: '#0093D0', secondary: '#fff' },
  });

  let firstLongToastId: string | undefined;
  let secondLongToastId: string | undefined;

  const firstTimeoutId = setTimeout(() => {
    firstLongToastId = toast.loading(
      'We know this is taking a bit longer, but please bear with us.',
      { duration: Infinity, icon: <>&#128059;</> }
    );
  }, 15000);

  const secondTimeoutId = setTimeout(() => {
    toast.dismiss(firstLongToastId);
    secondLongToastId = toast.error(
      "Wow this is reeeaaaally taking long. How many products are you trying to load?! If this takes much longer please let dev know, ideally with the list of filters you're using and your page size.",
      { duration: Infinity, icon: <>&#128552;</> }
    );
  }, 60000);

  // some cleanup on abort
  signal?.addEventListener('abort', () => {
    isActive = false;
    clearTimeout(firstTimeoutId);
    clearTimeout(secondTimeoutId);
  });

  let deals: ApiProduct[] = [];
  try {
    if (limit && limit > MAX_PAGE_SIZE) {
      // NOTE: seems to mostly be working, but haven't tested it with pagination yet
      for (let x = 0; x < Math.ceil(limit / MAX_PAGE_SIZE); x++) {
        const dealsBatch = await queryDeals({
          select,
          orderBy,
          limit: MAX_PAGE_SIZE,
          page: page + x,
          signal,
        });
        deals.push(...dealsBatch);
      }
    } else {
      deals = await queryDeals({ select, limit, page, orderBy, signal });
    }

    if (isActive) {
      setRows([...deals.map(prepProductRow)]);
      toast.success('Deals loaded');
    }
  } catch (e) {
    console.error(e);

    isActive &&
      toast.error(
        e instanceof Error
          ? e.message
          : 'Error loading deals. Please try again.',
        { duration: 15000 }
      );
  } finally {
    clearTimeout(firstTimeoutId);
    clearTimeout(secondTimeoutId);

    isActive && setIsLoading(false);

    firstLongToastId && toast.dismiss(firstLongToastId);
    secondLongToastId && toast.dismiss(secondLongToastId);
    toast.dismiss(loadingToastId);
  }
};

const SearchDeals = ({
  openImagePreview,
  duplicateDeal,
}: {
  openImagePreview: (args: { src: string; width: number }) => void;
  duplicateDeal: (dealId: string) => void;
}) => {
  const [rows, setRows] = useState<RowType<GridProduct>[]>([]);
  const [changedRows, setChangedRows] = useState<RowType<GridProduct>[]>([]);
  const [selectQuery, setSelectQuery] = useState<SelectQuery[]>([]);
  const [orderBy, setOrderBy] = useState({
    field: 'activeFromDate',
    direction: SORT_DIR.desc,
  });
  const [pageSize, setPageSize] = useState(20);
  const [currentPage] = useState(1);

  const priorities = useAttributeOptions(AttributeCode.priority);

  const sortedPriorities: Priority[] = useMemo(
    () =>
      ((priorities || []).slice(0) || [])
        .filter(x => x.key !== 'NONE')
        // search uses the IDs instead of the enums like create/update
        .map(priority => ({ ...priority, value: priority.originalData.value }))
        .sort((a, b) => {
          if (+a.key < +b.key) {
            return -1;
          }
          if (+a.key > +b.key) {
            return 1;
          }
          return 0;
        }),
    [priorities]
  );

  // filters context
  const { setIsLoadingDeals: setIsLoading } = useSearchFiltersContext();
  const activeFilters = useActiveFilters();
  const activeFiltersLabel = useActiveFiltersLabel();

  // editor context
  const { changes, setChanges, setUploadChangesCallback } =
    useSearchEditorContext();

  const loadDealsCallback = useCallback(
    (signal: AbortSignal | undefined = undefined) => {
      loadDeals({
        select: selectQuery,
        limit: pageSize,
        page: currentPage,
        orderBy,
        setIsLoading,
        setRows,
        signal,
      });
    },
    [selectQuery, pageSize, currentPage, orderBy, setIsLoading]
  );

  /**
   * Triggering deal load.
   */
  useEffect(() => {
    // NOTE: if there are no select parameters then we don't really want to waste effort on API calls
    if (selectQuery.length === 0) {
      setRows([]);
    } else {
      const controller = new AbortController();
      loadDealsCallback(controller.signal);
      return () => controller.abort();
    }
  }, [selectQuery, loadDealsCallback]);

  /**
   * Creating our select query object.
   */
  useEffect(() => {
    const select: SelectQuery[] = [];

    activeFilters.forEach(filter => {
      const key = filter.key;

      // abort if we don't have a field map entry
      if (!(key in filterFieldMap)) return;

      // ranges are a bit different to the rest, so handle them first
      if ([FilterType.range].includes(filter.type)) {
        if (Array.isArray(filter.value)) {
          const [from, to] = filter.value;

          select.push(
            ...[
              {
                field: filterFieldMap[key],
                condition: filterConditionMap.greaterThanOrEqualsTo,
                value: from.toString(),
              },
              {
                field: filterFieldMap[key],
                condition: filterConditionMap.lessThanOrEqualsTo,
                value: to.toString(),
              },
            ]
          );
        }

        // we're done prepping the select for this field a bit early
        return;
      }

      // prep the condition
      let condition: string | undefined;
      if ([FilterType.text].includes(filter.type)) {
        condition = filter.exact
          ? filterConditionMap.exact
          : filterConditionMap.like;
      } else if ([FilterType.select, FilterType.search].includes(filter.type)) {
        condition = filterConditionMap.exact;
      } else if ([FilterType.date].includes(filter.type)) {
        condition = filter.exact
          ? filterConditionMap.exact
          : filter.key === 'activeFromDate'
          ? filterConditionMap.greaterThanOrEqualsTo
          : filterConditionMap.lessThanOrEqualsTo;
      } else if ([FilterType.boolean].includes(filter.type)) {
        condition = filterConditionMap.exact;
      }

      // abort if we have no condition
      if (!condition) return;

      // prep the value
      //set empty value when no categories selected and filter is active
      if ('categories' === key && !filter.value) {
        filter.value = '';
      }
      let value = filter.value;
      if ([FilterType.date].includes(filter.type) && value instanceof Date) {
        value = dateObjectToIso(value, true);
      } else if ([FilterType.boolean].includes(filter.type)) {
        value = !!value ? '1' : '2';
      }

      // prep the select query for this field
      if (typeof value === 'string') {
        const filterInput = {
          field: filterFieldMap[key],
          condition,
          value,
        };

        select.push(filterInput);
      }
    });

    setSelectQuery(select);
  }, [activeFilters]);

  /**
   * Get page size from localStorage.
   */
  useEffect(() => {
    const storedPageLimit = localStorage.getItem('user-page-limit');
    if (storedPageLimit) {
      const parsed = Number(storedPageLimit);
      if (!isNaN(parsed)) {
        setPageSize(parsed);
      }
    }
  }, []);

  const productChange = useCallback(
    ({
      product,
      field,
      value,
    }: {
      product: GridProduct;
      field: string;
      value: unknown;
    }) => {
      let newValue: unknown | undefined;
      const currentValue = product[field];
      switch (field) {
        case 'activeFromDate':
        case 'activeToDate':
          if (typeof value === 'string') {
            newValue = value;
          }
          break;
        case 'priority':
          if (typeof value === 'number') {
            newValue = value;
          }
          break;
        default:
        // not supported
      }

      if (typeof newValue === 'undefined') {
        console.error(
          `Cannot update product with data: ${JSON.stringify({
            product,
            field,
            value,
          })}`
        );
        return;
      }

      // setting it here for a quick visual update to users
      // but the actual change happens in a useEffect
      // so that these can be removed easily as well
      setChangedRows(rows => [
        ...rows.map(row => {
          if (row.rowId === product.id) {
            row.data[field] = newValue;
          }
          return row;
        }),
      ]);

      setChanges(changes => {
        let nextChanges = [...changes];
        const existing = nextChanges.find(change => change.id === product.id);
        if (existing) {
          if (typeof existing.fields[field] !== 'undefined') {
            if (existing.fields[field].original === newValue) {
              delete existing.fields[field];
            } else {
              existing.fields[field].new = newValue;
            }
          } else {
            existing.fields = {
              ...existing.fields,
              ...{ [field]: { original: currentValue, new: newValue } },
            };
          }

          // replace the deal change while retaining order
          nextChanges = nextChanges.map(change =>
            change.id === product.id ? existing : change
          );
        } else {
          nextChanges.push({
            id: product.id,
            isSampleReceived: product.isSampleReceived,
            isPhotographedByStudio: product.isPhotographedByStudio,
            fields: { [field]: { original: currentValue, new: newValue } },
          });
        }

        return nextChanges;
      });
    },
    [setChanges]
  );

  /**
   * Update rows based on user changes.
   */
  useEffect(() => {
    // update displayed product info
    // TODO: BP-577: would be good to highlight this field/input
    setChangedRows([
      ...rows.map(row => {
        const change = changes.find(change => change.id === row.rowId);
        if (!change) return row;

        const changedRow = { ...row };
        Object.entries(change.fields).forEach(([field, value]) => {
          if (typeof value !== 'undefined' && value.original !== value.new) {
            changedRow.data[field] = value.new;
          }
        });
        return changedRow;
      }),
    ]);
  }, [rows, changes]);

  /**
   * Set callback to run after uploading changes.
   */
  useEffect(() => {
    setUploadChangesCallback(() => loadDealsCallback);
  }, [setUploadChangesCallback, loadDealsCallback]);

  /**
   * Render grid.
   */
  return (
    <>
      <div style={{ marginTop: '-25px' }}>
        {rows.length === 0 && activeFilters.length === 0 && (
          <span>Select filters...</span>
        )}

        {rows.length === 0 && activeFilters.length !== 0 && (
          <span>No results found...</span>
        )}

        {rows.length > 0 && (
          <SearchDealsGrid
            rows={changedRows}
            pageSize={pageSize}
            pageSizeOptions={pageSizeOptions}
            setPageSize={(value: number) => {
              localStorage.setItem('user-page-limit', value.toString());
              setPageSize(value);
            }}
            orderBy={orderBy}
            setOrderBy={setOrderBy}
            priorities={sortedPriorities}
            productChange={productChange}
            openImagePreview={openImagePreview}
            activeFiltersLabel={activeFiltersLabel}
            duplicateDeal={duplicateDeal}
          />
        )}
      </div>
    </>
  );
};

export default SearchDeals;
