import { generatePath } from 'react-router-dom';
import { Flex } from '@odo/components/elements/layout';
// grid
import Grid from '@odo/components/grid';
import { AccountLoaders, Dates, Deal, Image, Pricing } from './columns';
import type { RowType, SORT_DIR } from '@odo/components/grid/types';
import type { Priority, GridProduct } from '@odo/components/search/types';
// elements
import Button from '@odo/components/elements/button';
import { Checkbox } from '@odo/components/elements/form-fields';
import { getImgixAssetUrl } from '@odo/utils/make-url';
// styles
import styled from '@odo/lib/styled';
import { cssColor } from '@odo/utils/css-color';
// icons
import {
  FaCalculator as CalculatorIcon,
  FaCheckCircle as EnabledStatusIcon,
  FaPen as EditDealIcon,
  FaRegCopy as DuplicateDealIcon,
  FaRegEye as PreviewIcon,
  FaArrowCircleRight,
} from 'react-icons/fa';
import { IoMdCloseCircle } from 'react-icons/io';
import Tooltip from '@odo/components/widgets/tooltip';

const ROW_HEIGHT = 120;
const ROW_CHUNK_SIZE = 20; // NOTE: because of the alternating row colors, this chunk size is best as an even number.
const ROOT_MARGIN = '300px';

const orderByFieldMap = {
  name: 'details',
  price: 'pricing',
  activeFromDate: 'dates',
};

const SearchDealsGrid = ({
  rows,
  orderBy,
  setOrderBy,
  priorities,
  productChange,
  openImagePreview,
  duplicateDeal,
  activeFiltersLabel,
  ...restProps
}: {
  rows: RowType<GridProduct>[];
  pageSize: number;
  setPageSize: (size: number) => void;
  pageSizeOptions: number[];
  orderBy: { field: string; direction: SORT_DIR };
  setOrderBy: (value: { field: string; direction: SORT_DIR }) => void;
  priorities: Priority[];
  productChange: (value: {
    product: GridProduct;
    field: string;
    value: unknown;
  }) => void;
  openImagePreview: (args: { src: string; width: number }) => void;
  duplicateDeal: (dealId: string) => void;
  activeFiltersLabel?: string;
}) => (
  <Grid
    rows={rows}
    rowHeight={ROW_HEIGHT}
    rowChunkSize={ROW_CHUNK_SIZE}
    rootMargin={ROOT_MARGIN}
    orderBy={{
      field:
        orderBy.field in orderByFieldMap
          ? orderByFieldMap[orderBy.field]
          : orderBy.field,
      direction: orderBy.direction,
    }}
    footerCenterCopy={activeFiltersLabel}
    {...restProps}
    columns={[
      /**
       * Bulk Selection
       */
      {
        id: 'isSelected',
        title: 'Bulk',
        width: '40px',
        render: ({ data }: { data: GridProduct }) => (
          <Checkbox checked={data.isSelected} />
        ),
      },
      /**
       * ID
       */
      {
        id: 'id',
        title: 'ID',
        width: '60px',
        render: ({ data }: { data: GridProduct }) => <Text>{data.id}</Text>,
        sort: ({ id, dir }) => setOrderBy({ field: id, direction: dir }),
      },
      /**
       * Status
       */
      {
        id: 'status',
        title: 'Status',
        width: '50px',
        render: ({ data }: { data: GridProduct }) => (
          <>
            <Text title={data.status ? 'Enabled' : 'Disabled'}>
              {data.status ? (
                <EnabledStatusIcon color={cssColor('success')} size="20px" />
              ) : (
                <IoMdCloseCircle color={cssColor('error')} size="22px" />
              )}
            </Text>
            {!!data.xtdDaysConfirmed && (
              <div>
                <Tooltip
                  showDelay={300}
                  hideDelay={200}
                  placement="bottom"
                  content={() => 'This is an Extra Time Deal'}
                >
                  <FaArrowCircleRight
                    color={cssColor('palette-magenta-light')}
                    size="20px"
                  />
                </Tooltip>
              </div>
            )}
          </>
        ),
        sort: ({ id, dir }) => setOrderBy({ field: id, direction: dir }),
      },
      /**
       * Image
       */
      {
        id: 'image',
        title: 'Thumbnail',
        width: '80px',
        render: ({ id, data }: { id: string; data: GridProduct }) => (
          <Image
            id={id}
            data={data}
            zoomCallback={imgSrc =>
              openImagePreview({
                src: getImgixAssetUrl({ url: imgSrc, width: 800, height: 800 }),
                width: 800,
              })
            }
          />
        ),
      },
      /**
       * Brand
       */
      {
        id: 'brand',
        title: 'Brand',
        width: '80px',
        render: ({ data }: { data: GridProduct }) => (
          <Text title={data.brand}>{data.brand}</Text>
        ),
        sort: ({ dir }) => setOrderBy({ field: 'brand', direction: dir }),
      },
      /**
       * Name
       */
      {
        id: 'name',
        title: 'Name',
        width: '150px',
        render: ({ data }: { data: GridProduct }) => (
          <Text title={data.name}>{data.name}</Text>
        ),
        sort: ({ dir }) => setOrderBy({ field: 'name', direction: dir }),
      },
      /**
       * SKU
       */
      {
        id: 'sku',
        title: 'SKU',
        width: '130px',
        render: ({ data }: { data: GridProduct }) => (
          <Text title={data.sku}>{data.sku}</Text>
        ),
        sort: ({ dir }) => setOrderBy({ field: 'sku', direction: dir }),
      },
      /**
       * SHOP/CAT
       */
      {
        id: 'shops',
        title: 'Cat / Shop',
        width: '130px',
        render: ({ data }: { data: GridProduct }) => (
          <Text
            title={(data.categories || [])
              .slice(0, 3)
              .map(cat => cat.name)
              .join(', ')}
          >
            {(data.categories || [])
              .slice(0, 3)
              .map(cat => cat.name)
              .join(', ')}
          </Text>
        ),
        sort: ({ dir }) => setOrderBy({ field: 'shops', direction: dir }),
      },
      /**
       * Pricing
       */
      {
        id: 'pricing',
        title: 'Pricing',
        width: '140px',
        render: Pricing,
        sort: ({ dir }) => setOrderBy({ field: 'pricing', direction: dir }),
      },
      /**
       * Qty
       */
      {
        id: 'qty',
        title: 'Qty',
        width: '50px',
        render: ({ data }: { data: GridProduct }) => (
          <TextEllipsis>
            {data.qty !== null ? data.qty.toString() : 'N/A'}
          </TextEllipsis>
        ),
        sort: ({ dir }) => setOrderBy({ field: 'qty', direction: dir }),
      },
      /**
       * Dates
       */
      {
        id: 'dates',
        title: 'Dates',
        width: '150px',
        render: ({ data }: { data: GridProduct }) => <Dates data={data} />,
        sort: ({ dir }) =>
          setOrderBy({ field: 'activeFromDate', direction: dir }),
      },
      /**
       * Account Manager
       */
      {
        id: 'buyer',
        title: 'Account Manager',
        width: '140px',
        render: AccountLoaders,
        sort: ({ dir }) => setOrderBy({ field: 'buyer', direction: dir }),
      },
      /**
       * Deal Types
       */
      {
        id: 'dealType',
        title: 'Deal Types',
        width: '125px',
        render: Deal,
        sort: ({ dir }) => setOrderBy({ field: 'dealType', direction: dir }),
      },
      /**
       * Priority
       */
      {
        id: 'priority',
        title: 'Priority',
        width: '60px',
        render: ({ data }: { id: string; data: GridProduct }) => (
          <Select
            style={{ width: '50px' }}
            defaultValue={data.priority || undefined}
            onChange={e =>
              productChange({
                product: data,
                field: 'priority',
                value: +e.target.value,
              })
            }
          >
            {priorities.map(p => (
              <option key={p.value} value={p.value || undefined}>
                {p.key}
              </option>
            ))}
          </Select>
        ),
        sort: ({ id, dir }) => setOrderBy({ field: id, direction: dir }),
      },
      /**
       * Action
       */
      {
        id: 'actions',
        title: 'Actions',
        width: '60px',
        render: ({ data }: { data: GridProduct }) => (
          <Flex
            gap="1px"
            style={{
              flexWrap: 'wrap',
            }}
          >
            <a
              href={generatePath('/deals/editor/:dealId?/buyer-and-supplier', {
                dealId: data.id,
              })}
              target="_blank"
              rel="noreferrer noopener"
              title="Edit existing deal"
            >
              <Button hue="grey" variant="flat" circular px={1} py={1}>
                <EditDealIcon size="1.7rem" />
              </Button>
            </a>

            <Button
              hue="grey"
              variant="flat"
              circular
              px={1}
              py={1}
              onClick={() => duplicateDeal(data.id)}
              title="Duplicate Deal"
            >
              <DuplicateDealIcon size="1.7rem" />
            </Button>

            {!!data.priceForecastUrl && (
              <a
                href={data.priceForecastUrl}
                target="_blank"
                rel="noreferrer noopener"
                title="Price forecast"
              >
                <Button hue="grey" variant="flat" circular px={1} py={1}>
                  <CalculatorIcon size="1.7rem" />
                </Button>
              </a>
            )}

            {!!data.preview && (
              <a
                href={data.preview}
                target="_blank"
                rel="noreferrer noopener"
                title="Preview"
              >
                <Button hue="grey" variant="flat" circular px={1} py={1}>
                  <PreviewIcon size="1.7rem" />
                </Button>
              </a>
            )}
          </Flex>
        ),
      },
    ]}
  />
);

const TextEllipsis = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 11px;
`;

export const Text = styled.span`
  font-size: 11px;
  word-wrap: break-word;
`;
// custom Select to fix Safari and FireFox
export const Select = styled.select`
  padding-left: 4px;
  border: 1px solid #c2c2c2;
  border-radius: 4px;
  box-shadow: none;
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  background: transparent
    url('data:image/gif;base64,R0lGODlhBgAGAKEDAFVVVX9/f9TU1CgmNyH5BAEKAAMALAAAAAAGAAYAAAIODA4hCDKWxlhNvmCnGwUAOw==')
    right center no-repeat !important;
  background-position: calc(100% - 5px) center !important;
`;

export default SearchDealsGrid;
