import { ValidationModel } from './base/ValidationModel.jsx';
import { dateObjectToIso, daysAway } from '@odo/utils/date';

export const PLACEHOLDER_DESCRIPTION = `
<h5>About</h5>
<p>###</p>
<h5>Product Features</h5>
<ul>
<li>###</li>
</ul>
<h5>Product Specifications</h5>
<ul>
	<li>###</li>
</ul>
`;

export const ProductValidators = {
  partial: {
    name: {
      validator: x => x.name.length > 0,
      error: 'You must enter a Product Name',
    },
    datesChosen: {
      validator: x => !!x.activeFromDate && !!x.activeToDate,
      error: 'Please choose a date for this deal',
    },
    datesNotOverlapping: {
      validator: model =>
        model.isTimedDeal
          ? new Date(`${model.activeFromDate} ${model.activeFromTime}`) <=
            new Date(`${model.activeToDate} ${model.activeToTime}`)
          : new Date(model.activeFromDate) <= new Date(model.activeToDate),
      error: "'Active From' Date cannot be greater than 'Active To' Date",
    },
    sku: {
      validator: x => x.sku?.length > 0,
      error: 'You must enter a SKU',
    },
    brand: {
      validator: x => x.brand,
      error: 'You must enter a Brand name',
    },
    area: {
      validator: x => x.area,
      error: 'An area of availability must be chosen',
    },
  },

  complete: {
    shortName: {
      validator: x => x.shortName?.length > 0,
      error: 'You must enter a Short Name',
    },

    dateWarning: {
      validator: model => {
        const startDate = model.isTimedDeal
          ? new Date(`${model.activeFromDate} ${model.activeFromTime}`)
          : new Date(model.activeFromDate);
        const endDate = model.isTimedDeal
          ? new Date(`${model.activeToDate} ${model.activeToTime}`)
          : new Date(`${model.activeToDate} 23:59:59`);
        const today = new Date();
        return !(today >= startDate && today <= endDate);
      },
      error: 'Dates overlap with today - deal will go live immediately!',
    },
  },
};

export const ProductFields = {
  name: '',
  shortName: '',
  sku: '',
  condition: null,
  features: [],
  moreDetails: PLACEHOLDER_DESCRIPTION,
  taxClass: null,
  status: false,
  isPreviewOnly: false,
  area: '',
  activeFromDate: dateObjectToIso(daysAway(1), false),
  activeToDate: dateObjectToIso(daysAway(1), false),
  isTimedDeal: false,
  activeFromTime: '00:00',
  activeToTime: '23:59',
  pillOne: '',
  pillTwo: '',
  brand: '',
  url: '',
  buyInStockType: null,
  preview: null,
  xtdDaysRequested: '',
  xtdDaysConfirmed: '',
};

export class DealProduct extends ValidationModel {
  constructor() {
    super(ProductFields, ProductValidators);

    // The following is done to provide autocomplete for dynamic properties.
    // Not strictly necessary but a nice-to-have.
    /**
     * Product name
     * @type {string}
     * @public
     */
    this.name;

    /** @type {string} */
    this.shortName;

    /** @type {string} */
    this.sku;

    /** @type {string} */
    this.condition;

    /** @type {string} */
    this.features;

    /** @type {string} */
    this.moreDetails;

    /** @type {string} */
    this.taxClass;

    /** @type {string} */
    this.area;

    /** @type {Boolean} */
    this.status;

    /** @type {Boolean} */
    this.isPreviewOnly;

    this.activeFromDate;
    this.activeToDate;
    this.activeFromTime;
    this.activeToTime;
    this.isTimedDeal;
    this.xtdDaysRequested;
    this.xtdDaysConfirmed;
    this.pillOne;
    this.pillTwo;
    this.brand;
    this.buyInStockType;
    this.preview;
  }
}

DealProduct.FIELDS = {
  NAME: 'name',
  SHORT_NAME: 'shortName',
  SKU: 'sku',
  CONDITION: 'condition',
  FEATURES: 'features',
  DESCRIPTION: 'moreDetails',
  TAXABLE_CLASS: 'taxClass',
  AREA: 'area',
  ENABLED: 'status',
  IS_PREVIEW_ONLY: 'isPreviewOnly',
  ACTIVE_FROM_DATE: 'activeFromDate',
  ACTIVE_TO_DATE: 'activeToDate',
  ACTIVE_FROM_TIME: 'activeFromTime',
  ACTIVE_TO_TIME: 'activeToTime',
  IS_TIMED_DEAL: 'isTimedDeal',
  XTD_DAYS_REQUESTED: 'xtdDaysRequested',
  XTD_DAYS_CONFIRMED: 'xtdDaysConfirmed',
  PILL1: 'pillOne',
  PILL2: 'pillTwo',
  BRAND: 'brand',
  BUY_IN_STOCK_TYPE: 'buyInStockType',
  URL: 'url',
};
